import React, {useState,useRef} from "react";
import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
	Dimensions,
	Pressable
} from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import Footer from './Footer';
import ccc_sign from '../images/ccc-sign.png';
import mapinfo from '../json_files/roadmap-info.json';


const {width} = Dimensions.get('window');


const styles=StyleSheet.create({
	leftBar: {
		height:'100%',
		width:'100%',
		borderTopRightRadius:'10px',
		borderBottomRightRadius:'10px'
	},
	rightBar: {
		height:'100%',
		width:'100%',
		borderTopLeftRadius:'10px',
		borderBottomLeftRadius:'10px'
	}
})


const MapSlide = (props) => {
	
	
	
	
	
	const renderBorder = (sideWanted) => {
		if (props.side == sideWanted){
			return (
				<View style={{
					flex:1
				}}>
					<LinearGradient 
						start={{x: 0, y: 0}} end={{x:0, y: 1}}
						colors={['#2e7e78','#935be9']}
						style={ (props.side == 'left') ? styles.leftBar : styles.rightBar}>
						
					</LinearGradient>
				</View>
			)
		}
	}
	
	return(
		<View style={{
			width:'100%',
			flexDirection:'row',
			marginTop:'10%',
			marginBottom:'10%',
			backgroundColor:''
		}}>
			
			{renderBorder('left')}
			
			<View style={{width:'95%',height:'100%'}}>
			{/* slide Title */}
			<View style={{width:'100%', justifyContent:'center',alignItems:'center', backgroundColor:''}}>
				<Text style={{width:'90%',color:'white',fontFamily:'lores-15',fontSize:'2.5em',backgroundColor:'',textAlign:props.side}}>
					{props.mapInfo[0]}
				</Text>
			</View>
			
			{/* slide text */}
			<View style={{width:'100%',alignItems:'center',marginTop:'2%',backgroundColor:''}}>
				<Text style={{color:'white',width:'80%',fontSize:'1.3em',fontFamily:'consolas',textAlign:'center'}}>
					{props.mapInfo[1]}
				</Text>
			</View>
		</View>
		
		{renderBorder('right')}
		
		</View>
				
			
	)
}


const Roadmap = (props) => {
	
	const [mapdata, setMapdata] = useState(mapinfo);
	
	const pageRef = useRef(null);
	
	const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
		console.log('hit');
  };
	
	
	return(
		<View ref={pageRef} style={{
			height:'auto',
			width:'100%',
			alignItems:'center',
			flexDirection:'column',
			// backgroundColor:'black'
			// justifyContent:'center'
		}}>
			
			
			<View style={{
				width:'95%',
				height:'40vh'
			}}>
				<Image source={ccc_sign} style={{width:'100%', height:'100%'}} />
			</View>
			
			<View style={{width:'100%',alignItems:'center',marginTop:'10%',marginBottom:'5%'}}>
				<Text style={{color:'white',fontSize:'2em',fontFamily:'lores-15'}}>
					ROADMAP ACTIVATIONS
				</Text>
			</View>
			
			<View style={{width:'95%'}}>
				<Text style={{color:'white',fontFamily:'consolas',}}>
					Our biggest goal is to get the entire collection out of our hands to build a community as well as fill 
					the community treasury. With that in mind, the following goals have been assigned to percentage of the 
					collection sold to motivate people to join the Cutthroat Country Club! Once we hit a target sell through 
					percentage, we will begin to work on realizing the stated goal.
				</Text>
			</View>
			
			<View style={{width:'100vw',alignItems:'center',marginTop:'10%'}}>
				<MapSlide side="right" mapInfo={mapdata.goalOne}  />
				<MapSlide side="left" mapInfo={mapdata.goalTwo}  />
				<MapSlide side="right" mapInfo={mapdata.goalThree}  />
				<MapSlide side="left" mapInfo={mapdata.goalFour}  />
				<MapSlide side="right" mapInfo={mapdata.goalFive}  />
				
				
			</View>
			
				
			<Footer toTop={scrollToTop}/>
       
           
         
         
         
       
			
			
		</View>
	)
}


export default Roadmap;






















