import React, {useState,useEffect} from "react";

import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable
} from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import {NativeRouter,Routes, Route,useLocation} from 'react-router-native';

import {IconContext} from 'react-icons';
import {RiMenu4Fill} from 'react-icons/ri';
import * as checker from '../connector';

import {injected} from '../connectors.js';
import {Web3ReactProvider,useWeb3React} from '@web3-react/core';
import Web3 from 'web3';

import Navbar from './NavBar';
import NavMenu from './NavMenu';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import Roadmap from './Roadmap';
import GovernancePlaceHolder from './GovernancePlaceHolder';
import WhitelistMinting from './WhitelistMinting';



// primary color to use
// #935be9








const AppInner= () => {
  
  const [showMenu, setShowMenu] = useState(false);
  const [freezeMain,setFreezeMain] = useState(false);
  
  // useEffect(() => {
  //   checker.setStorage();
  //   const latestPage = sessionStorage.getItem('lastPage');
  // 
  //   console.log(latestPage);
  // },[])

  
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    toggleFreeze();
  }
  
  const toggleFreeze = () => {
    setFreezeMain(!freezeMain);
  }
  
  const location = useLocation();
  
  const styles=StyleSheet.create({
    mainPage:{
      height:'100vh',
      backgroundColor:'black',
      overflowX:'hidden',
      overflowY:'scroll'
    },
    mainStatic:{
      height:'100vh',
      backgroundColor:'black',
      overflowX:'hidden',
      overflowY:'hidden'
    }
  })
  
  const getLibrary = (provider) => {
  	return new Web3(provider);
  }
  
  // const storeLastPage = (page) => {
  //   sessionStorage.setItem('lastpage',page);
  // }
  
  
  return (
    <Web3ReactProvider library={getLibrary}>
      
    
      <SafeAreaView style={freezeMain ? styles.mainStatic : styles.mainPage }>
        <View 
          style={{
            height:'100%',
          }}>
        
          
        <Navbar menutoggle={toggleMenu} />
        { showMenu && <NavMenu menutoggle={toggleMenu} />}
        
        
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/aboutpage'  element={<AboutPage />}/>
          <Route exact path='/roadmap' element={<Roadmap />} />
          <Route exact path='/governance' element={<GovernancePlaceHolder />} />
          <Route exact path='/whitelistminting' element={<WhitelistMinting />} />
        </Routes>
                
            
              
            
        
        
        </View>
      </SafeAreaView>
    </Web3ReactProvider>
      
    
  );
};


const App = () => {
  
  return (
    <NativeRouter>
      <AppInner />
    </NativeRouter>
  
);
}


export default App;


























