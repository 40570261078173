import React, {useState,useEffect} from 'react';
import {Routes,Route,useLocation ,Link} from 'react-router-dom';
import {AnimatePresence,useAnimation ,motion} from 'framer-motion';
import ClickAwayListener from 'react-click-away-listener';

import {injected} from '../connectors.js';
import {Web3ReactProvider,useWeb3React} from '@web3-react/core';
import Web3 from 'web3';



import {IconContext} from 'react-icons';
import {FiMenu} from 'react-icons/fi';

import HomePage from './HomePage';
import RoadMap from './Roadmap';
import AboutPage from './AboutPage';
import GovernancePlaceHolder from './GovernancePlaceHolder';
import WhiteListMinting from './WhitelistMinting';

import '../css_files/App.css';
import * as checker from '../connector';
import generalLinks from '../json_files/general_links.json';


// needed images
import ccc_logo from '../images/ccc-logo-clear.png';
import discord_icon from '../images/discord-icon.png';
import twitter_icon from '../images/twitter-icon.png';
import opensea_icon from '../images/opensea-icon.png';
import WalletConnect_icon from '../images/WalletConnect-logo.png';
import metamask_icon from '../images/metamask-logo.png'
import coinbase_icon from '../images/coinbase-icon.png';
import ccc_favicon from '../images/ccc-favicon.png';
import calender_logo from '../images/nftcalender-logo.png'

// ^^^^^^^^^^^^


/*
███    ██  █████  ██    ██ ██████   █████  ██████
████   ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██
██ ██  ██ ███████ ██    ██ ██████  ███████ ██████
██  ██ ██ ██   ██  ██  ██  ██   ██ ██   ██ ██   ██
██   ████ ██   ██   ████   ██████  ██   ██ ██   ██
*/




const NavBar = () => {
	
	
	const showrline = event => {
		document.querySelector('#roadmap-underline').style.opacity=1;
	}
	const hiderline = event => {
		document.querySelector('#roadmap-underline').style.opacity=0;
	}
	
	const showcline = event => {
		document.querySelector('#connect-underline').style.opacity=1;
	}
	const hidecline = evet => {
		document.querySelector('#connect-underline').style.opacity=0;
	}
	
	useEffect(() => {
		
		const rbutton = document.querySelector('#roadmap-button');
		const cbutton = document.querySelector('#connect-button');
	
	
		rbutton.addEventListener('mouseover', showrline);
		cbutton.addEventListener('mouseover', showcline);
	
		rbutton.addEventListener('mouseout', hiderline);
		cbutton.addEventListener('mouseout', hidecline);
	
		return () => {
			rbutton.removeEventListener('mouseover',showrline);
			cbutton.removeEventListener('mouseover',showcline);
			rbutton.removeEventListener('mouseout',hiderline);
			cbutton.removeEventListener('mouseout',hidecline);
		}
	
	},[])
	
	
	const dupVarients = {
		start:{
			x:0
		},
		end:{
			x:0,
			transition:{
				staggerChildren:.25
			}
			
		}
	}
	
	const mediaVariants = {
		start:{
			x:-1000
		},
		end:{
			x:0,
			transition:{
				type:'spring',
				mass:.5,
				damping: 12,
				duration:.8
			}
		}
	}
	
	const navVariants = {
		start:{
			x:1000
		},
		end:{
			x:0,
			transition:{
				type:'spring',
				mass:.5,
				damping: 12,
				duration:.8
			}
		}
	}
	
	return (
		
		<div className='navbar-body'>
			
				
			<div className='media-section'>
				<motion.div className='media-container'
					variants={dupVarients}
					initial='start'
					animate='end'
					>
					
					<motion.div key='animation-1' id='media-icon-one' className='media-button'
						variants={mediaVariants}
						>
							<a href={generalLinks.discord}>
								<img src={discord_icon} className='media-icon'  />
							</a>
								
					</motion.div>
					
					<motion.div key='animation-2' className='media-button'
						variants={mediaVariants}
						>
						<a href={generalLinks.twitter}>
							<img src={twitter_icon} className='media-icon' />
						</a>
							
					</motion.div>
					
					<motion.div key='animation-3' className='media-button'
						variants={mediaVariants}
						>
						<a href={generalLinks.opensea}>
							<img src={opensea_icon} className='media-icon' />
						</a>
							
					</motion.div>
					
				</motion.div>
			</div>
			
			
			
				<motion.div className='logo-section'
					initial={{
						opacity:0
					}}
					animate={{
						opacity:1,
						transition:{
							duration:1
						}
					}}
					>
						
					<div className='logo-link'>
					<Link to='/'>
					<img src={ccc_logo} className='ccc-logo' />
					</Link>	
					</div>
					
				</motion.div>
			
				
			<div className='nav-section'>
				
				<motion.div className='nav-container'
					variants={dupVarients}
					initial='start'
					animate='end'
					>
					
					<motion.div variants={navVariants}>
						<Menu/>
					</motion.div>
						
					<Link to='/roadmap'>
					<motion.div id='roadmap-button' className='button-container'
						variants={navVariants}
						>
						
							<button className='nav-button'>
								<div className='button-inner'><div className='is-family-primary'>ROADMAP</div></div>
								<div id='roadmap-underline' className='underline'></div>
							</button>
						
					</motion.div>
					</Link>
					
					<motion.div className='button-container'
						variants={navVariants}
						>
						
						<ConnectMenu />
						
					</motion.div>
					
				</motion.div>
				
				
			</div>
			
		</div>
		
	)
}

/*
███████  ██████   ██████  ████████ ███████ ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
█████   ██    ██ ██    ██    ██    █████   ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
██       ██████   ██████     ██    ███████ ██   ██
*/


const Footer = () => {
	
	return (
		<div className='footer-main-body'>
			<div className='footer-inner-body'>
				<div className='left-side'>
					<img className='footer-image' src={ccc_favicon} />
				</div>
				
				<div className='middle-side'>
					<div className='footer-nav is-family-secondary'>
						
						<a href='' style={{ textDecoration: 'none' }}>
							<div className='footer-nav-item' >GOVERNANCE</div>
						</a>
							
						<Link to='/roadmap' style={{ textDecoration: 'none', color:'white' }}>
							<div className='footer-nav-item' >ROADMAP</div>
						</Link>
						<Link to='/about' style={{ textDecoration: 'none', color:'white' }}>
							<div className='footer-nav-item' >ABOUT</div>
						</Link>	
							
						
					</div>
				</div>
				
				<div className='right-side'>
					<div className='footer-icon'>
						<a href={generalLinks.discord}>
							<img src={discord_icon} className='media-icon'  />
						</a>
					</div>
							
						
					<div className='footer-icon'>
						<a href={generalLinks.twitter}>
							<img src={twitter_icon} className='media-icon' />
						</a>
							
					</div>
						
					<div className='footer-icon'>
						<a href={generalLinks.opensea}>
							<img src={opensea_icon} className='media-icon' />
						</a>
					</div>
					
					<div className='footer-icon'>
						<a href={"https://nftcalendar.io"}>
							<img src={calender_logo} className='media-icon' />
						</a>
					</div>
					
						
				</div>
					
			</div>
		</div>
		
	)
}


/*
 ██████  ██████  ███    ██ ███    ██ ███    ██ ███████  ██████ ████████     ███    ███ ███████ ███    ██ ██    ██
██      ██    ██ ████   ██ ████   ██ ████   ██ ██      ██         ██        ████  ████ ██      ████   ██ ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ ██ ██  ██ █████   ██         ██        ██ ████ ██ █████   ██ ██  ██ ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██  ██ ██ ██      ██         ██        ██  ██  ██ ██      ██  ██ ██ ██    ██
 ██████  ██████  ██   ████ ██   ████ ██   ████ ███████  ██████    ██        ██      ██ ███████ ██   ████  ██████
*/


var counter=0;


const ConnectMenu = (props) => {
	
	const [open, setOpen] = useState(false);
	var [loggedIn, setLoggedIn] = useState(false);
	var [connectedTitle,setConnectedTitle] = useState('CONNECTED');
	const {active,account,library,connector,activate,deactivate} = useWeb3React();
	
	
	const textVariant = {
		initial : {
			opacity:0,
			transition:{
				duration:.5
			}
		},
		animate : {
			opacity:1,
			transition:{
				duration:.5
			}
		},
		leave : {
			opacity:0,
			transition:{
				duration:.5
			}
		}
	}
	
	useEffect(() => {
		checkWallet();
	},[]);
	
	
	const checkWallet = () => {
		if (eval(sessionStorage.getItem('connected'))) {
			connect();
		}
	}
	
	
	const connect = async() => {
		
		
		try {
			
			await activate(injected);
			sessionStorage.setItem('connected','true');
			
		} catch(ex) {
			console.log(ex);
		}
	}
	
	const disconnect = async() => {
		
		try {
			await deactivate(injected);
			sessionStorage.setItem('connected','false');
			
		} catch(ex) {
			console.log(ex);
		}
		
	}
	
	
	const connectHandler = () => {
		
		if (active) {
			disconnect();
		}
		else {
			connect();
		}
	}
	
	
	
	const WalletItem = (props) => {
		
		return (
			<div className='wallet-item is-family-primary'>
				<div className='wallet-image'>
					<img className='wallet-icon'  src={props.icon} />
				</div>
					
				
				<div className='wallet-name'>
				<div className='name-inner'>{props.itemName}</div>
				</div>
			</div>
		)
	}
	
	
	
	
	const Address = () => {
		const formatAddress = (item) => {
			const sizeLimit = 10;
			const shrunk = item.substring(0,sizeLimit)+'...';

			try {
				return shrunk;
			}
			catch {
				return '';
			}
				
		}
		
		return(
			<motion.div className='address-holder'
				initial={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				animate={{
					opacity:1,
					transition:{
						duration:.5
					}
				}}
				exit={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}>
				{formatAddress(account)}
			</motion.div>
		)
	}
	
	const ConnectText = () =>{
		return(
			<motion.div
				key = 'c-indicator1'
				initial = 'initial'
				animate = 'animate'
				exit = 'leave'
				>
				CONNECT
			</motion.div>
		)
	}
	
	const ConnectedText = () =>{
		return(
			<motion.div
				key = 'c-indicator2'
				initial = 'initial'
				animate = 'animate'
				exit = 'leave'
				>
				CONNECTED
			</motion.div>
		)
	}
	
	const DisconnectText = () =>{
		return(
			<motion.div
				key = 'c-indicator3'
				initial = 'initial'
				animate = 'animate'
				exit = 'leave'
				>
				LOGOUT
			</motion.div>
		)
	}
	
	
	return ( 
		<div className='connect '>
			<button id='connect-button' onClick={connectHandler} className='nav-button'>
				<div id='connect-button-inner' className='button-inner is-family-primary'>
						{active ? <ConnectedText />:<ConnectText />}
				</div>
				<div id='connect-underline' className='underline'></div>
			</button>
		
		
		
		<AnimatePresence>
			{active && <Address />}
		</AnimatePresence>
		</div>
	)
} 


/*
███    ███ ███████ ███    ██ ██    ██
████  ████ ██      ████   ██ ██    ██
██ ████ ██ █████   ██ ██  ██ ██    ██
██  ██  ██ ██      ██  ██ ██ ██    ██
██      ██ ███████ ██   ████  ██████
*/




const Menu = () => {
	const [open, setOpen] = useState(false);
	
	
	const DropDown = () => {
		
		const DropDownItem = (props) => {
			
			return (
				
					<div className='drop-item'>
						<div className='drop-inner is-family-primary'>
							{props.title}
						</div>
					</div>
				
				
			)
		}
		
		return (
			<motion.div className='drop-menu'
				initial={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				animate={{
					opacity:1,
					transition:{
						duration:.5
					}
				}}
				exit={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				>
				<ClickAwayListener onClickAway={() => {setOpen(!open)}}>
				<div>
					<Link to='/governance' style={{ textDecoration: 'none' }}>
						<DropDownItem title='GOVERNANCE'/>
					</Link>
						
					<Link to='/about' style={{ textDecoration: 'none' }}>
						<DropDownItem title='ABOUT' />
					</Link>
					
					<Link to='/whitelistminting' style={{ textDecoration: 'none' }}>
						<DropDownItem title='WHITELIST' />
					</Link>
						
				</div>
				</ClickAwayListener>
				
			</motion.div>
			
		)
	}
	
	const [flag,setFlag] = useState(false);
	
	
	return (
		
			<div className='menu-button' onClick= { async() => {
				await setOpen(!open);
			}}>
				<IconContext.Provider
					value={{color:'#935be9', className:'menu-icon'}}>
					<FiMenu />
				</IconContext.Provider>
				<AnimatePresence>
					{open && <DropDown />}
				</AnimatePresence>
					
			</div>
		
		
	)
}


const getLibrary = (provider) => {
	return new Web3(provider);
}



const WebsiteApp = () => {
	const location = useLocation();
	checker.setStorage();
	
	useEffect(() => {
		// console.log(window.ethereum);
	},[])
	
	return ( 
		
			<div>
				
				
				<Web3ReactProvider getLibrary={getLibrary}>
				
					<NavBar />
				
					<AnimatePresence mode='wait'>
						<Routes location={location} key={location.pathname}>
							<Route exact path='/' element={<HomePage />} />
							<Route exact path='/roadmap' element={<RoadMap />} />
							<Route exact path='/about' element={<AboutPage />}/>
							<Route exact path='/governance' element={<GovernancePlaceHolder />}/>
							<Route exact path='/whitelistminting' element={<WhiteListMinting />}/>
						</Routes>
					</AnimatePresence>
				
				
					<Footer />
				
				</Web3ReactProvider>
				
			</div>
		
			
		
	)
}
export default WebsiteApp;

