import React, {useState,useEffect} from "react";

import {Link} from 'react-router-native';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable
} from 'react-native';

import LinearGradient from 'react-native-web-linear-gradient';
import ClickAwayListener from 'react-click-away-listener';
import {injected} from '../connectors.js';
import {Web3ReactProvider,useWeb3React} from '@web3-react/core';
import Web3 from 'web3';



import {IconContext} from 'react-icons';
import {RiMenu4Fill,RiCloseCircleLine} from 'react-icons/ri';

import '../css_files/fonts.css';
import discord_icon from '../images/discord-icon.png';
import twitter_icon from '../images/twitter-icon.png';
import opensea_icon from '../images/opensea-icon.png';
import calender_logo from '../images/nftcalender-logo.png';
import metamask_logo from '../images/metamask-logo.png';
import coinbase_logo from '../images/coinbase-icon.png';

import generalLinks from '../json_files/general_links.json';





const getLibrary = (provider) => {
	return new Web3(provider);
}



const MenuItem = (props) => {
	
	return(
		<View style={{
			marginTop:'5%',
			marginBottom:'5%',
			// alignItems:'center'
		}}>
			
			<Text style={{
				color:'#935be9',
				fontSize:'1.9em',
				fontFamily:'lores-15'
			}}>
				{props.itemName}
			</Text>
			
			
		</View>
	)
	
}

const ConnectButton = (props) => {
  const {active,account,library,connector,activate,deactivate} = useWeb3React();
  
  
  
  useEffect(() => {
    const previous = eval(sessionStorage.getItem('connected'));
    const current = active;
    
    if (previous){
      if(!current) {
        try {
          connect();
        }
        catch(err) {
          console.log(err);
        }  
      }
    }
    
  },[])
  
  
  
  
  
  const connect = async(props) => {
		
		try {
      
			await activate(injected);
      sessionStorage.setItem('connected','true');
      
      
        
			
		} catch(ex) {
			console.log(ex);
		}
	}
  
  const disconnect = async(props) => {
		try {
			await deactivate(injected);
			sessionStorage.setItem('connected','false');
			
		} catch(ex) {
			console.log(ex);
		}
		
	}
  
  const connectHandler = () => {
    
    if (active) {
      disconnect();
    }
    else {
      
      if (window.ethereum != undefined) {
        connect();
      }
      else {
        props.optionShow();
      }
    }
  }
  
  
  return(
    <Pressable onPress={() => {
      connectHandler();
    }}>
      
    
      <View style={{
        marginTop:'5%',
  			marginBottom:'5%',
      }}>
      
      
        <Text style={{
          color:'#935be9',
          fontFamily:'lores-15',
          fontSize: '1.9em'
        }}>
          {active ? 'CONNECTED':'CONNECT'}
        </Text>
        
        
      
      
      </View>
      
      
    </Pressable>
        
      
  )
}


const ConnectOptions = (props) => {
  
  const styles=StyleSheet.create({
    optionSytle : {
      height:'50%'
    }
  })
  
  const Option = (props) => {
    return(
      <View style={{width:'100%',height:'100%',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
        
        <View style={{height:'100%',width:'40%',alignItems:'center'}}>
          <Image source={props.logo} resizeMode='contain' style={{height:'100%',width:'60%'}}/>
        </View>
        
        <View style={{height:'100%',width:'60%',justifyContent:'center'}}>
          <Text style={{color:'#935be9',fontFamily:'lores-15',fontSize:'1.75em'}}>
            {props.name}
          </Text>
        </View>
          
      </View>
    )
  }
  
  
  return(
    <View style={{
      width:'100%',
      height:'100%',
      position:'absolute',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'rgba(0,0,0,.5)'
    }}>
      
      <ClickAwayListener onClickAway={() => {
        props.closeOptions();
      }}>
        <View style={{
          width:'60%',
          height:'20%',
          borderRadius:'15px',
          backgroundColor:'rgb(20,20,20)'
        }}>
          
          <Pressable href="https://metamask.app.link/dapp/www.cutthroatcountryclub.com/" style={styles.optionSytle}>
            <Option logo={metamask_logo} name='MetaMask' />
          </Pressable>
          {/* https://go.cb-w.com/dapp?cb_url=[https%3A%2F%2Fwww.cutthroatcountryclub.com%2F] */}
          <Pressable href="https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fwww.cutthroatcountryclub.com%2F" style={styles.optionSytle}>
            <Option logo={coinbase_logo} name='CoinBase' />
          </Pressable>
            
        
          
        </View>
      </ClickAwayListener>
    
      
    </View>
    
  )
}


const NavMenu = (props) => {
  
  const [showOptions, setShowOptions] = useState(false);
  
  
  
  const toggleOptions = () => {
    // console.log(showOptions);
    setShowOptions(!showOptions);
  }
  
	const closeMenu = () => {
		props.menutoggle();
	}
  
	
  return(
		<Web3ReactProvider getLibrary={getLibrary}>
      <View style={{
        width:'100%',
        height:'100vh',
        position:'absolute',
  			zIndex:1,
  			alignItems:'center',
  			justifyContent:'center',
        backgroundColor:'black'
      }}>
  		
      
      
      <View style={{
  			width:'10%',
  			position:'absolute',
  			right:'3vh',
  			top:'3vh'
  		}}>
  			<Pressable onPress={closeMenu}>
  				<IconContext.Provider
  					value={{size:'100%',color:'#935be9'}}>
  					<RiCloseCircleLine />
  				</IconContext.Provider>
  			</Pressable>
  			
  		</View>
  		
  		
  		<View style={{
  			width:'60%',
  			borderRadius:'10px',
  			// backgroundColor:'rgb(20,20,20)'
  		}}>
  		
  		
      <Link onPress={closeMenu} to='/governance'>
        <MenuItem itemName={'GOVERNANCE'} />
      </Link>
        
  		<Link onPress={closeMenu} to='/aboutpage'>
  			<MenuItem itemName={'ABOUT'} />
  		</Link>
  		
  		<Link onPress={closeMenu} to='/roadmap'>
  			<MenuItem itemName={'ROADMAP'} />
  		</Link>
  		
      <Link onPress={closeMenu} to='/whitelistminting'>
  			<MenuItem itemName={'WHITELIST'} />
  		</Link>
      
			<ConnectButton optionShow={toggleOptions}/>
  		
      
      
  		<View style={{
  			width:'100%',
  			height:'30%',
  			flexDirection:'row',
  			// backgroundColor:'red'
  		}}>
  		<Pressable href={generalLinks.discord} style={{height:'100%',width:'15%',margin:'5%'}}>
  			<Image resizeMode='contain' source={discord_icon} style={{
  				height:'100%',
  				width:'100%',
  				margin:'5%'
  			}} />
  		</Pressable>
  		
  		<Pressable href={generalLinks.twitter} style={{height:'100%',width:'15%',margin:'5%'}}>
  		<Image resizeMode='contain' source={twitter_icon} style={{
  			height:'100%',
  			width:'100%',
  			margin:'5%'
  		}} />
  		</Pressable>
  		
  		<Pressable href={generalLinks.opensea} style={{height:'100%',width:'15%',margin:'5%'}}>
  			<Image resizeMode='contain' source={opensea_icon} style={{
  				height:'100%',
  				width:'100%',
  				
  			}} />
  		</Pressable>
      
      <Pressable href={"https://nftcalendar.io"} style={{height:'100%',width:'15%',margin:'5%'}}>
  			<Image resizeMode='contain' source={calender_logo} style={{
  				height:'100%',
  				width:'100%',
  				
  			}} />
  		</Pressable>
  			
  		</View>
  		
  		
  		
  			
  		</View>
      {showOptions && <ConnectOptions closeOptions={toggleOptions}/>}
    </View>
 </Web3ReactProvider> 
		
  )
}


export default NavMenu;