import React, {useState,useEffect} from 'react';
import {motion} from 'framer-motion';
import mapinfo from '../json_files/roadmap-info.json';


import ccc_sign from '../images/ccc-sign.png';
import '../css_files/Roadmap.css';


const RoadMap = () => {
		
	const [mapdata, setMapdata] = useState(mapinfo);
	const colors={'box-1': '#915ce7',
								'box-2': '#7865ca',
								'box-3': '#5f6daf',
								'box-4': '#457692',
								'box-5': '#2e7e78'}
	
	
	function gradient_mover(event,item) {
		
		const object = item;
		const color = colors[object.id];
		
		
		
		
		var rect = object.getBoundingClientRect();
		var x = event.clientX - rect.left;
		var y = event.clientY - rect.top;
		
		const center = [object.offsetWidth/2,object.offsetHeight/2];
		const dif = [center[0]-x,center[1]-y];
		var deg = Math.atan(dif[1]/dif[0]) * (180/Math.PI);
		const dist = Math.sqrt((dif[0]**2) + (dif[1]**2));
		const d_perc = ((((center[0]+10)-dist)/(center[0]+10))/2)*100; //the 10 is just a buffer for the max distance
		const l_perc = (10-d_perc);
		const oppose = 100 - l_perc;
		
		
		if (dif[0] > 0) {
			if (deg < 0) {
				deg = 90-(deg * -1)
			}
			if (dif[1] > 0) {
				deg = deg + 90;
			}
		}
		else if (dif[0] < 0) {
			if (deg > 0) {
				deg = (90 - deg) * -1;
			}
			if (dif[1] > 0) {
				deg = deg - 90;
			}
		}
		
		const back = `linear-gradient(${deg}deg , ${color} ${l_perc}%, black)`;
		object.style.backgroundImage = back ;
			
	}	
	
	function content_hover(event, contId) {
		var new_target = event.target;
		
		while ( new_target.className != 'box-inner') {
			new_target = new_target.parentNode;	
		}
		const tid = new_target.id.split('-');
		const point=document.querySelector('#inner-'+tid[tid.length-1].toString());
		const container = new_target;
		
		point.style.backgroundColor=colors[new_target.id];
		new_target.addEventListener('mousemove',(event) =>{ gradient_mover(event,new_target)});
		
		// removes mousemove when mouse leaves element
		container.addEventListener('mouseout', function() {
			container.removeEventListener('mousemove', gradient_mover);
			container.style.backgroundImage = 'none';
			container.style.backgroundColor = 'black';
			point.style.backgroundColor='black';
		});
			
	}
	
	
	
	
	function set_roadmap() {
		
		const items = document.querySelectorAll('.box-inner');
		
		for (const item of items){
			item.addEventListener('mouseover',content_hover);
		}
		
		
		
		const line = document.querySelector('.center-line');
		const spacing = line.offsetHeight/5;
		
		var acum = -10;
		const outer_boxes = document.getElementsByClassName('map-block');
		const inner_boxes = document.getElementsByClassName('map-content');
		
		
		for (var i=0; i<5; i++) {
			// set up dots on line
			
			
			const outer_circle = document.createElement('div');
			const inner_circle = document.createElement('div');
			const c_object = document.createElement('div');
			
			
			c_object.className='center_object';
			outer_circle.className = 'outer-circle';
			inner_circle.className = 'inner-circle';
			
			outer_circle.setAttribute('id','outer-'+(i+1).toString());
			inner_circle.setAttribute('id','inner-'+(i+1).toString());
			
			outer_circle.style.backgroundColor = colors['box-'+(i+1).toString()];
			
			outer_circle.appendChild(inner_circle);
			c_object.appendChild(outer_circle);
			line.appendChild(c_object);
			
			outer_circle.style.top = (acum).toString()+'px';
			
			acum = acum + spacing + 15;
		}
	}
		
		
		const MapBlock = (props) => {
			
			return (
				<div id={'block-' + props.cid} className={props.cname}>
					<div id={'box-' + props.cid} className='box-inner'>
						<div className='box-title'>
							<div className='title-holder is-family-primary'>
								{props.goalData[0]}
							</div>
						</div>
								
						<div id={'description-'+props.cid} className='box-description is-family-primary'>
							<div className='description-holder'>
								{props.goalData[1]}
							</div>
						</div>
								
						
					</div>
				</div>
				
			)
		}
	
	
		const Centerline = () => {
			
			return(
				<div className='center-line'>
				</div>
			)
		}
	
	
		useEffect(() => {
			set_roadmap();
			
		} ,[])
	
	
	
	return (
		<motion.div className='roadmap-body'
			initial={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}
			animate={{
				opacity:1,
				transition: {
					duration:.5
				}
			}}
			exit={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}>
			
			<div className='sign-holder'>
				<img className='ccc-sign' src={ccc_sign} />
			</div>
			
			<div className='roadmap-title is-family-secondary'>
				ROADMAP ACTIVATIONS
			</div>
			<div className='roadmap-statement is-family-primary'>
				Our biggest goal is to get the entire collection out of our hands 
				to build a community as well as fill the community treasury. 
				With that in mind, the following goals have been assigned to 
				percentage of the collection sold to motivate people to join 
				the Cutthroat Country Club! Once we hit a target sell through 
				percentage, we will begin to work on realizing the stated goal.
			</div>
			
			<div className='map-holder'>
				<div id='left-column'  className='column'>
					 <MapBlock cid='2' cname='left-box' goalData={mapdata.goalTwo}/>
					 <MapBlock cid='4' cname='left-box' goalData={mapdata.goalFour}/>
				</div>
				<Centerline />
				<div id='right-column' className='column'>
					<MapBlock cid='1' cname='right-box' goalData={mapdata.goalOne}/>
					<MapBlock cid='3' cname='right-box' goalData={mapdata.goalThree}/>
					<MapBlock cid='5' cname='right-box' goalData={mapdata.goalFive}/>
				</div>
				
			</div>
			
			
		</motion.div>
	)
} 



export default RoadMap;