import React from "react";
import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text, 
  useColorScheme,
  View,
  Image,
  ImageBackground,
  Button,
  Pressable,
  Dimensions
} from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';

import discord_icon from '../images/discord-icon.png';
import twitter_icon from '../images/twitter-icon.png';
import opensea_icon from '../images/opensea-icon.png';
import calender_logo from '../images/nftcalender-logo.png'

import generalLinks from '../json_files/general_links.json';

import {IconContext} from 'react-icons';
import {RiArrowUpSLine} from 'react-icons/ri';



const Footer = (props) => {
	
	
	return(
		<LinearGradient 
			start={{x: 0.0, y: 0}} end={{x:0, y: 1}}
			colors={['#2e2e2e','black']} style={{height:'10vh',width:'100%'}}>
			<View style={{width:'100%',height:'100%',flexDirection:'row'}}>
      
      
			
				<View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
	        <Image source={require('../images/ccc-favicon.png')} resizeMode='contain' style={{width:'50%',height:'65%'}} />
	      </View>
				
				
			
			
      
        <View style={{
    			flex:2,
    			flexDirection:'row',
          alignItems:'center',
          justifyContent:'flex-end'
          
    		}}>
    				<Pressable href={generalLinks.discord} style={{height:'100%',width:'10%',margin:'5%'}}>
      				<Image resizeMode='contain' source={discord_icon} style={{
      					height:'100%',
      					width:'100%',
      					margin:'5%'
      				}} />
            </Pressable>
    				
    				<Pressable href={generalLinks.twitter} style={{height:'100%',width:'10%',margin:'5%'}}>
    				<Image resizeMode='contain' source={twitter_icon} style={{
    					height:'100%',
    					width:'100%',
    					margin:'5%'
    				}} />
            </Pressable>
    				
            <Pressable href={generalLinks.opensea} style={{height:'100%',width:'10%',margin:'5%'}}>
      				<Image resizeMode='contain' source={opensea_icon} style={{
      					height:'100%',
      					width:'100%',
      					
      				}} />
            </Pressable>
            
            <Pressable href={"https://nftcalendar.io"} style={{height:'100%',width:'10%',margin:'5%'}}>
      				<Image resizeMode='contain' source={calender_logo} style={{
      					height:'100%',
      					width:'100%',
      					
      				}} />
            </Pressable>
    				
    			
    		</View>
  		
			</View>
    </LinearGradient>
	)
}



export default Footer;

