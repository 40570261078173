import React, {useState,useEffect} from "react";
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable
} from 'react-native';


import {ethers} from 'ethers';
import '../css_files/fonts.css';
import {injected} from '../connectors.js';
import {Web3ReactProvider,useWeb3React} from '@web3-react/core';
import Web3 from 'web3';

import Footer from './Footer';

import key from "../blockchain/private_key.json";
import nft_abi from "../blockchain/ccc-nft.json";
import contractInfo from "../json_files/contract-data.json";
import sharedInfo from "../json_files/shared-info.json";



const getLibrary = (provider) => {
	return new Web3(provider);
}



/*
███████ ███████ ████████ ██    ██ ██████
██      ██         ██    ██    ██ ██   ██
███████ █████      ██    ██    ██ ██████
     ██ ██         ██    ██    ██ ██
███████ ███████    ██     ██████  ██
*/


var provider=null;
var contract=null;
var wallet = null;
var accounts = null;
var signer=null;

const loadWeb3 = async() => {
				if (window.ethereum) {
					window.ethereum.enable();
					provider = await new ethers.providers.Web3Provider(window.ethereum);
				}
			}
			
			
async function createWallet() {
	accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
	signer=await provider.getSigner(accounts[0]);
	// wallet =  await new ethers.Wallet(key.privateKey,provider);
}

async function loadContract() {
	
	const tempContract = await new ethers.Contract(contractInfo.contractAddress,nft_abi,provider);
	const withSigner = await tempContract.connect(signer);
	contract = withSigner;
}

async function setup() {
	await loadWeb3();
	await createWallet();
	await loadContract();
}



setup();









/*
███    ███  █████  ██ ███    ██        ██
████  ████ ██   ██ ██ ████   ██       ███
██ ████ ██ ███████ ██ ██ ██  ██ █████  ██
██  ██  ██ ██   ██ ██ ██  ██ ██        ██
██      ██ ██   ██ ██ ██   ████        ██
*/


const WhitelistInner = () => {
	const {active,account,library,connector,activate,deactivate} = useWeb3React();
	var prevResult=eval(sessionStorage.getItem('connected'));
	
	// const [isWhitelisted,SetWhitelisted] = useState(false);
	const [whitelisted, setWhitelisted] = useState(false);
	const [allowance, setAllowance] = useState(0);
	const [mintRate, setMintRate] = useState('');
	const [mintError, setMintError] = useState('');
	
	
	function resetError() {
		setMintError('');
	}
	
	function parseError(errorMessage) {
		const items = errorMessage.message;
		const startPosition = items.search('code')-2;
		const endPosition = items.search('originalError')-10;
		const trimmed = items.substring(startPosition,endPosition)+'}';
		const jsoned = JSON.parse(trimmed);  
		return jsoned.message;
	}
	
	
	async function checkCredentials() {
		var attempts=0;
		// keeps checking accounts until not null
		const checker = setInterval(async() => {
			if (accounts!=null) {
				
				
				const isOnWhitelist = await contract.isWhitelisted(accounts[0]);
				
				if (isOnWhitelist) {
					
					const ammount = await contract.whitelistAllowance(accounts[0]);
					const price = await ethers.utils.formatEther(await contract.whiteListMintRate());
				
					setAllowance(ammount);
					setMintRate(price);
				}
				setWhitelisted(isOnWhitelist);
				killChecker();
			} 
		},1000);
		
		const killChecker = () => {
			clearInterval(checker);
		}
	}
		
		
		
	
	// checks if the connected wallet is on the whitelist
	async function onloadCheck() {
		checkCredentials(); 
		resetError();
		// doStuff();
	}
	
	
	async function isWhitelisted() {
		const whitelisted = await contract.isWhitelisted(accounts[0]);
		return whitelisted;
	} 
	
	// returns whether the wallet has enough ether to mint an nft
	async function checkBalance() {
		const currentBalance = ethers.utils.formatEther(await provider.getBalance(accounts[0]));
		const mintPrice = ethers.utils.formatEther(await contract.whiteListMintRate());
		
		if (currentBalance > mintPrice) {
			
			resetError();
			return true;
		}
		else {
			return false;
		}
		
	}
	
	
	
	
	// mints an nft
	async function whitelistMint() {
		const hasMoney = await checkBalance();
		
		if (hasMoney) {
			const ethvalue = await contract.whiteListMintRate();
			const signer = provider.getSigner(accounts[0]);
			contract = await contract.connect(signer);
			try {
				const mintAttempt = await contract.safeMint({value:ethers.utils.parseEther('0.04')});	
			} catch(err) {
				setMintError(parseError(err));
			}
			checkCredentials();
				
			
		} 
		else if (!hasMoney) {
			setMintError('current balance is lower than the mintrate');
		}
		
	}
	
	async function doStuff() {
		const signer = await provider.getSigner(accounts[0]);
		contract = contract.connect(signer); 
		
		const estGas = await contract.estimateGas.AddToWhitelist(['0x353f4592E1f131CaC58c369fA9758ea37283DAf6'],['1']);
		const item = await contract.AddToWhitelist(['0x353f4592E1f131CaC58c369fA9758ea37283DAf6'],['1']);
	}
	
	
	/*
	 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████ ██  ██████  ███    ██
	██      ██    ██ ████   ██ ████   ██ ██      ██         ██    ██ ██    ██ ████   ██
	██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██    ██ ██    ██ ██ ██  ██
	██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██    ██ ██    ██ ██  ██ ██
	 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██    ██  ██████  ██   ████
	*/

	
	const connect = async(props) => {
		
		try {
      
			await activate(injected);
			sessionStorage.setItem('connected','true');
			
		} catch(ex) {
			console.log(ex);
		}
	}
	
	const disconnect = async(props) => {
		try {
			await deactivate(injected);
			sessionStorage.setItem('connected','false');
			
		} catch(ex) {
			console.log(ex);
		}
		
	}
	
	
	const isAuthorized = async() => {
		return await injected.isAuthorized();
	}
		
	
	
	const checkWallet = async() => {
		const authorized = await isAuthorized();
		// console.log('hit');
		if (authorized) {
			const connected = eval(sessionStorage.getItem('connected'));
			
			if (connected && !active) {
				await connect();
			}
			else if (!connected && active) {
				await disconnect();
			}
			
			
		}
		
		
	}
	
	
		const observer = setInterval(() => {
			const connection = eval(sessionStorage.getItem('connected'));
			
			if (connection != prevResult) {
				
				if (connection) {
					connect();
				}else if (!connection){
					disconnect();
				}
				
				prevResult= connection;
			}
			
			// checkCredentials();
		
		},1000);
	
	
	
	useEffect(() => {
		onloadCheck();
		checkWallet();
		
		return () => {
			clearInterval(observer);
		}
	},[]);
	
	
	/*
	 ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ███████
	██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██
	██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ███████
	██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██         ██
	 ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ███████
	*/

	
	const NotConnected = () => {
		
		return(
			
			
		<View style={{height:'80vh',width:'75%',justifyContent:'center'}}>
			<View style={{
				alignItems:'center'
				}}>
				<Text style={{color:'#935be9',fontSize:'3em',fontFamily:'lores-15',
											marginTop:'10%',marginBottom:'10%'}}>
					Not Connected
				</Text>
			</View>
			
			<View style={{}}>
				<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.25em',
											marginTop:'2%',marginBottom:'2%'}}>
					It doesn't seem like your wallet is connected.
				</Text>
			</View>
			
			<View style={{}}>
				<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.25em',
											marginTop:'5%',marginBottom:'5%'}}>
					Do us a favor and press the connect button
				</Text>
			</View>
		</View>
						
			
			
		)
	}
	
	const Connected = () => {
		
		const styles = StyleSheet.create({
			wlnumbercontainer:{
				height:'100%',
			},
			wlnumber:{
				color:'#935be9',
				fontFamily:'lores-15',
				fontSize:'1.5em',
				
			},
			wltext:{
				color:'white',
				fontFamily:'consolas',
				fontSize:'1.25em'
			},
			wlstep:{
				flexDirection:'row',
				alignItems:'center',
				marginTop:'5%',
				marginBottom:'5%',
			}
		})
		
		const Step = (props) => {
			return(
				<View style={styles.wlstep}>
					<View style={styles.wlnumbercontainer}>
						<Text style={styles.wlnumber}> {props.number+'. '}</Text>
					</View>
						
					<Text style={styles.wltext}>
						{props.text}
					</Text>
				</View>
			)
		}
		
		const InfoItem = (props) => {
			return(
				<View style={{marginTop:'2%',marginBottom:'2%'}}>
					<View style={{flexDirection:'row',alignItems:'center',backgroundColor:''}}>
						<Text style={{color:'#935be9',fontSize:'2em',fontFamily:'lores-15'}}>
							{props.item + ": "}
						</Text>
						<Text style={{color:'white',fontSize:'1.5em',fontFamily:'consolas'}}>
							{props.info}
						</Text>
					</View>
				</View>
			)
		}
		
		
		const Mintable = () => {
			return(
				<View style={{height:'90vh',width:'90%', marginTop:'10%',marginBottom:'10%'}}>
					
					<View >
						
						<Text style={{color:'white',fontFamily:'lores-15',fontSize:'3em'}}>
							Welcome
						</Text>
						
						<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.25em',marginTop:'5%'}}>
							to the Whitelist, we've been expecting you. White Collar warriors from all over the 
							world have fought long and hard to become a
							<Text style={{color:'#935be9'}}> Cutthroat</Text>
							, but few have succeeded. 
							Looks like you've made the cut-
						</Text>
						
						<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.25em',marginTop:'5%'}}>
							Congrats!
						</Text>
						
					</View>
					
					<View style={{height:'10vh',alignItems:'center',justifyContent:'center'}}>
						<View style={{width:'100%',height:'8%',borderRadius:'50%',backgroundColor:'#935be9'}}>
						</View>
					</View>
					
          
					<View style={{alignItems:'center',justifyContent:'center',height:'40%', backgroundColor:''}}>
						
						<View style={{flexDirection:'row',alignItems:'center',marginTop:'2%',marginBottom:'2%'}}>
							<Text style={{color:'#935be9',fontFamily:'lores-15',fontSize:'2em'}}>
								Mints left:
							</Text>
							
							<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.75em'}}>
								{' '+allowance.toString()}
							</Text>
						</View>
						
						<View style={{flexDirection:'row',alignItems:'center',marginTop:'2%',marginBottom:'2%'}}>
							<Text style={{color:'#935be9',fontFamily:'lores-15',fontSize:'2em'}}>
								Price:
							</Text>
							
							<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.75em'}}>
								{' 0.04 ETH'}
							</Text>
						</View>
						
						
						<View style={{marginTop:'20%',width:'40%'}}>
							
							<Pressable onPress={whitelistMint} style={{width:'100%',borderWidth:'2px',justifyContent:'center',
																												borderColor:'#935be9',borderRadius:'25px',alignItems:'center',}}>
								<Text style={{color:'#935be9',fontFamily:'consolas',fontSize:'2.5em',justifyContent:'center'}}>
									MINT
								</Text>
							</Pressable>
								
						</View>
							
          </View>
							
						
						
					
							
					
					
				</View>
			)
		}
		
		const NotMintable = () => {
			return(
				<View style={{width:'85%',backgroundColor:''}}>
					
					<Text style={{color:'white',fontFamily:'lores-15',fontSize:'4em',marginBottom:'5%'}}>
						Hmm...
					</Text>
					<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.5em'}}>
						it doesn't look like you are on the whitelist.
					</Text>
					<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.5em'}}>
						{"\n"}
						Skills such as yours make it pretty far
						in this community...
					</Text>
					
					<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.5em'}}>
						{"\n"}
						Consider this offer:
					</Text>
					
					
					<View style={{height:'10vh',alignItems:'center',justifyContent:'center'}}>
						<View style={{width:'100%',height:'8%',borderRadius:'50%',backgroundColor:'#935be9'}}>
						</View>
					</View>
					
					<View style={{}}>
						
						<View>
							
							
							<Text style={{color:'white',fontFamily:'lores-15',fontSize:'1.5em',marginBottom:'5%'}}>
								To join our WL, complete the following steps:
							</Text>
							
							
							<Step number='1' text='Follow us on Twitter'/>
							<Step number='2' text='Like & Retweet our pinned post'/>
							<Step number='3' text='Join our Discord server'/>
							<Step number='4' text='Invite 5 friends to our Discord using your invite link'/>
							
							<View style={{height:'10vh',alignItems:'center',justifyContent:'center'}}>
								<View style={{width:'100%',height:'8%',borderRadius:'50%',backgroundColor:'#935be9'}}>
								</View>
							</View>
							
							<View style={{}}>
								<Text style={{color:'white',fontFamily:'consolas', fontSize:'1.25em'}}>
									Take a screenshot as proof that you have completed all the requirements & send it to the discord’s 
									“whitelist-proof” channel, & we will reserve your WL spot!
								</Text>
							</View>
							
						<View style={{marginTop:'8%',marginBottom:'2%'}}>
							
							<InfoItem  item='WL Price' info= {sharedInfo.wlPrice} />
							<InfoItem  item='Mint Limit' info= {sharedInfo.mintLimit} />
							<InfoItem  item='Total WL spots' info= {sharedInfo.totalSpots} />
							<InfoItem  item='Presale' info= {sharedInfo.presaleDate} />
							<InfoItem  item='Launch Price' info= {sharedInfo.launchPrice} />
							<InfoItem  item='Launch Date' info= {sharedInfo.launchDate} />
							
						</View>
							
							
							
							
						</View>
						
						
						
						
						
						
						
						
						
						
						
						
					</View>
					
					
					
					
				</View>
			)
		}
		
		return(
			<View style={{alignItems:'center'}}>
				{whitelisted ? <Mintable /> : <NotMintable/>}
			</View>
		)
	}
	
	
	return(
		<View style={{width:'100%',alignItems:'center',justifyContent:'center'}}>
			{active? <Connected /> : <NotConnected />}
		</View>
	)
}



const WhitelistMinting = (props) => {
	const {active,account,library,connector,activate,deactivate} = useWeb3React();
	
	
	
	
	


	
	return(
		<Web3ReactProvider getLibrary={getLibrary}>
			<WhitelistInner />
			<Footer />
		</Web3ReactProvider>
			
	)
}


export default WhitelistMinting;
















