import React, {useEffect} from "react";
import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button
} from 'react-native';

import Footer from './Footer'; 
import about from '../json_files/about-info.json';



const Seperate = () => {
	
	return(
		<View style={{width:'100%',height:'10vh',alignItems:'center',justifyContent:'center'}}>
			
			<View style={{
				width:'70%',
				height:'5px',
				backgroundColor:'#935be9',
				position:'relative',
				top:0,
				borderRadius:'50%'
			}}></View>
			
		</View>
	)
}

const AboutPage = () => {
  
  useEffect(() => {
    console.log(about.sectionOne);
  } ,[])
  
	
	return(
		<View style={{height:'100%',alignItems:'center'}}>
			
			<View style={{
				marginTop:'10%',
				marginBottom:'5%'
			}}>
				<Text style={{color:'white', fontSize:'3em',fontFamily:'lores-15'}}>
					Utility
				</Text>
			</View>
			
			<View style={{width:'90%'}}>
				
				<Text style={{color:'white', fontFamily:'consolas'}}>
					{about.sectionOne[0]}
				{'\n'}
				{'\n'}
					{about.sectionOne[1]}
        </Text>
				
			</View>
			
			<Seperate />
			
			<View style={{width:'90%'}}>
				
				<Text style={{color:'white', fontFamily:'consolas'}}>
          {about.sectionTwo[0]}
				{'\n'}
				{'\n'}
					{about.sectionTwo[1]}
				</Text>
				
			</View>
			
			<Seperate />
			
			<View style={{width:'90%',marginBottom:'10%'}}>
				
				<Text style={{color:'white', fontFamily:'consolas'}}>
          {about.sectionThree[0]}
				{'\n'}
				{'\n'}
					{about.sectionThree[1]}
				</Text>
				
			</View>
			
			
			
			
			<Footer />
		</View>
	)
}



export default AboutPage;