import { AppRegistry } from "react-native";
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


import App from "./react-website-mobile/App";
import WebsiteApp from './react-website/App';





if (isMobile) {
  AppRegistry.registerComponent("App", () => App);
  
  AppRegistry.runApplication("App", {
      rootTag: document.getElementById("root")
  });
  
}

if (isBrowser) {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    
    	<BrowserRouter>
        	<WebsiteApp />
    	</BrowserRouter>
    
  );
}
