import react, {useState} from 'react';
import {motion} from 'framer-motion';
import about from '../json_files/about-info.json';

import '../css_files/AboutPage.css'; 

import trust_no_one from '../images/trust-no-one.png';
import will_you_survive from '../images/will-you-survive.png';
import kill_or_die from '../images/kill-or-be-killed.png';

const Section = (props) => {
	
	const Title = (props) => {
		return(
			<div className='one-title is-family-primary'>{props.sectionTitle}</div>
		)
	}
	
	const renderImage = (sideWanted) => {
		if (props.side == sideWanted){
			return <img className='image-item' src={props.image} />
		}
	}
	
	
	return(
		
		<div className='section'>
			
			{renderImage('left')}
			
			
				
			<div className='content-align'>
				<div className='container'>
					<Title sectionTitle={props.title}/>
				
					<div className='one-text is-family-primary'>
					{props.info[0]}
					<br />
					<br />
					{props.info[1]}
					</div>
				</div>
			</div>
			
			{renderImage('right')}
			
		</div>
		
	)
}


const AboutPage = () => {
	
	
	
	
	
	return(
		<motion.div className='about-holder'
			initial={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}
			animate={{
				opacity:1,
				transition: {
					duration:.5
				}
			}}
			exit={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}
			>
			
			<Section title='Utility' image={trust_no_one} info={about.sectionOne} side='left'/>
			<Section image={kill_or_die} info={about.sectionTwo} side='right'/>
			<Section image={will_you_survive} info={about.sectionThree} side='left' />
			
			
			
		</motion.div>
	)
}









export default AboutPage;