import {useState, useEffect} from 'react';
import {convertTime} from './computeTime';
import {useAnimation,motion} from 'framer-motion';

import './CountDown.css';

const defaultRemainingTime = {
	s1:'0',
	s2:'0',
	m1:'0',
	m2:'0',
	h1:'0',
	h2:'0',
	d:'0'
}

const CountDownTimer = (props) => {
	
	var remainingTime = defaultRemainingTime;
	
	function setRemainingTime(newTime) {
		remainingTime=newTime;
	}
	
	
	const dControl = useAnimation();
	const h1Control = useAnimation();
	const h2Control = useAnimation();
	const m1Control = useAnimation();
	const m2Control = useAnimation();
	const s1Control = useAnimation();
	const s2Control = useAnimation();
	
	var [number, setNumber] = useState(0);
	
	const flip = async(control_module,new_number, lid)=> {
		
		document.querySelector('#'+lid+'-l1-bottom-leaf').innerHTML=new_number
		document.querySelector('#'+lid+'-l2-top-leaf').innerHTML=new_number
		await control_module.start('action');
		document.querySelector('#'+lid+'-l1-top-leaf').innerHTML = new_number
		document.querySelector('#'+lid+'-l2-bottom-leaf').innerHTML = new_number
		await control_module.start('start');
		// setNumber(number+1); 
	}
	
	
	useEffect(() => {
		const intervalId = setInterval(() => {
			updateRemainingTime(props.endTime);
		},1000);
	
		return () => clearInterval(intervalId);
	},[props.endTime])
	
	
	function updateRemainingTime(countdown) {
		const converted=convertTime(countdown);
		
		if (converted.s2 != remainingTime.s2) {
			flip(s2Control,converted.s2 ,'s2');
		}
		
		if (converted.s1 != remainingTime.s1) {
			flip(s1Control,converted.s1,'s1');
		}
		
		if (converted.m2 != remainingTime.m2) {
			flip(m2Control,converted.m2,'m2');
		}
		
		if (converted.m1 != remainingTime.m1) {
			flip(m1Control,converted.m1,'m1');
		}
		
		if (converted.h2 != remainingTime.h2) {
			flip(h2Control,converted.h2,'h2');
		}
		if (converted.h1 != remainingTime.h1) {
			flip(h1Control,converted.h1,'h1');
		}
		
		if (converted.d != remainingTime.d) {
			flip(dControl,converted.d,'days');
		}
		
		
		setRemainingTime(converted);
	}
	
	
	const LeafGroup = (props) => {
		
		// ---------------------varients
		const number_one_top = {
			start:{
				rotateX:0,
				transition:{
					duration:0
				},
				transitionEnd:{
					zIndex:1
				}
			},
			action:{
				rotateX:-90,
				transition:{
					duration:.2
				},
				transitionEnd: {
					zIndex:-1
				}
			}
		}
		
		const number_one_bottom = {
			start:{
				rotateX:90,
				
				transitionEnd:{
					zIndex:1
				}
			},
			action:{
				rotateX:0,
				transition:{
					duration:.2,
					delay:.17
				},
				transitionEnd: {
					zIndex:-1
				}
			}
		}
		// ------------------------
		
		
		
		return(
			<div>
			
			<div id={props.leafId} className='leaf-group'>
				
				<div className='leaf-group-top'>
					
					<motion.div id={props.leafId+'-l1-top-leaf'} className='top-leaf'
						style={{originY: 'bottom'}}
						variants={number_one_top}
						initial='start'
						animate={props.aControl}
						
						>
						{props.digit}
					</motion.div>
					
					<motion.div id={props.leafId+'-l1-bottom-leaf'} className='bottom-leaf'
						style={{rotateX:90,originY: 'top'}}
						variants={number_one_bottom}
						inital='start'
						animate={props.aControl}
						>
						1
					</motion.div>
					
				</div>
				
				
				<div className='leaf-group-bottom'>
					<div id={props.leafId+'-l2-top-leaf'} className='top-leaf'>
						1
					</div>
					<div id={props.leafId+'-l2-bottom-leaf'} className='bottom-leaf'>
						{props.digit}
					</div>
				</div>
				
			</div>
			
				
			
			</div>
			
			
		)
	}
	
	
	
	
	
	
	
	
	
	
	
	
	return (
		<div className='main-clock-body'>
			
			
			<div id='days' className='time-group'>
				<div className='time-title'><div className='title-inner'>Days</div></div>
				
				<div className='group-inner'>
					<LeafGroup digit={remainingTime.d} aControl={dControl} leafId='days' />
				</div>
			</div>
			
			<div id='hours' className='time-group'>
				<div className='time-title'><div className='title-inner'>Hours</div></div>
				<div className='group-inner'>
					<LeafGroup digit={remainingTime.h1} aControl={h1Control} leafId='h1' />
					<LeafGroup digit={remainingTime.h2} aControl={h2Control} leafId='h2' />
				</div>
			</div>
					
			
			<div id='minutes' className='time-group'>
				<div className='time-title'><div className='title-inner'>Minutes</div></div>
				<div className='group-inner'>
					<LeafGroup digit={remainingTime.m1} aControl={m1Control} leafId='m1' />
					<LeafGroup digit={remainingTime.m2} aControl={m2Control} leafId='m2' />
				</div>
			</div>
			
			<div id='seconds' className='time-group'>
				<div className='time-title'><div className='title-inner'>Seconds</div></div>
				<div className='group-inner'>
					<LeafGroup digit={remainingTime.s1} aControl={s1Control} leafId='s1' />
					<LeafGroup digit={remainingTime.s2} aControl={s2Control} leafId='s2' />
				</div>
			</div>
					
				
		</div>
	)
}


export default CountDownTimer;
















