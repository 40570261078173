import {useState, useEffect} from 'react';
import {convertTime} from './computeTime';
import '../../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text, 
  useColorScheme,
  View,
  Image,
	ImageBackground,
  Button,
  Pressable,
	Animated,
	TouchableOpacity
	
} from 'react-native';










const defaultRemainingTime = {
	ss:'0',
	ms:'0',
	hs:'0',
	ds:'0'
}

const CountDownTimer = (props) => {
	
	var [days, setDays] = useState('0');
	var [hours, setHours] = useState('0');
	var [minutes, setMinutes] = useState('0');
	var [seconds, setSeconds] = useState('0');
	
	var remainingTime = defaultRemainingTime;
	
	
	
	
	function setRemainingTime(newTime) {
		setDays(newTime.ds);
		setHours(newTime.hs);
		setMinutes(newTime.ms);
		setSeconds(newTime.ss);
	}

	
	
	
	useEffect(() => {
		const intervalId = setInterval(() => {
			updateRemainingTime(props.endTime);
		},1000);
	
		return () => clearInterval(intervalId);
	},[props.endTime])
	
	
	function updateRemainingTime(countdown) {
		const converted=convertTime(countdown);
		setRemainingTime(converted);
	}
	
	
	
	
	
	const styles = StyleSheet.create({
		timeGroup: {
			marginLeft:'1%',
			marginRight:'1%',
			margin:'1%',
			alignItems:'center'
		},
		timeText: {
			fontSize:'1.5em',
			color:'white',
			fontFamily:'lores-15'
		}
	});
	
	
	return (
		<SafeAreaView style={{
			width:'100%',
			height:'60%',
			alignItems:'center'
		}}>
			<ImageBackground source={require('../../images/alarm-clock.png')}
			resizeMode='contain'
			style={{
				width:'100%',
				height:'100%'
			}}>
				
			
			<View style={{
				width:'100%',
				height:'100%',
				flexDirection:'row'
			}}>
			<View style={{
				flexDirection:'row',
				alignItems:'center',
				justifyContent:'center',
				width:'100%',
				// marginTop:'2%'
			}}>
			
				{/* days */}
				<View style={styles.timeGroup}>
					<Text style={{color:'white',fontFamily:'lores-15'}}>Days</Text>
					<Text style={styles.timeText}>
						{days}
					</Text>
				</View>
						
				{/* hour */}
				<View style={styles.timeGroup}>
					<Text style={{color:'white',fontFamily:'lores-15'}}>Hours</Text>
					<Text style={styles.timeText}>
						{hours}
					</Text>
				</View>
				{/* minute */}
				<View style={styles.timeGroup}>
					<Text style={{color:'white',fontFamily:'lores-15'}}>Minutes</Text>
					<Text style={styles.timeText}>
						{minutes}
					</Text>
				</View>
				{/* second */}
				<View style={styles.timeGroup}>
					<Text style={{color:'white',fontFamily:'lores-15'}}>Seconds</Text>
					<Text style={styles.timeText}>
						{seconds}
					</Text>
				</View>
				
				
			
			</View>
			</View>
			
			
			</ImageBackground>
		</SafeAreaView>
	)
}


export default CountDownTimer;
















