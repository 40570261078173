import React, {useState} from "react";
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable
} from 'react-native';
import { Link } from 'react-router-native';
import {IconContext} from 'react-icons';
import {RiMenu4Fill, RiHome2Line} from 'react-icons/ri';


import NavMenu from './NavMenu';


const Navbar = (props) => {
  
  
  
  
  
  
  return(
    <View style={{
      height:'10vh',
      backgroundColor:'black',
      marginTop:'2%'
    }}>
    
      <View style={{width:'100%',height:'100%',flexDirection:'row',}}>
        
        
        <Link to='/' style={{flex:10,alignItems:'center',backgroundColor:''}}>
            <Image resizeMode='contain' style={{
              width:'100%',
              height:'100%'
            }}
               source={require('../images/ccc-logo-clear.png')} />
        </Link>
       
        <View style={{flex:2,
         alignItems:'center',
         justifyContent:'center',
         flexDirection:'row',
         }}>
         
         
         <Pressable style={{width:'100%'}}
           onPress={() => {props.menutoggle()}}
           >
           <IconContext.Provider
             value={{size:'70%',color:'#935be9'}}>
             <RiMenu4Fill />
           </IconContext.Provider>
         </Pressable>
           
        </View>
      </View>
     
    </View>
  ) 
}



export default Navbar;