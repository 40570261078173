import React, {useState,useEffect} from "react";
import Timer from '../Timer/native_timer/CountDown';
import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text, 
  useColorScheme,
  View,
  Image,
  ImageBackground,
  Button,
  Pressable,
  Dimensions
} from 'react-native';
import {Link} from 'react-router-native';
import LinearGradient from 'react-native-web-linear-gradient';

import {IconContext} from 'react-icons';
import {RiCloseCircleLine} from 'react-icons/ri';

import Navbar from './NavBar';
import NavMenu from './NavMenu'
import Footer from './Footer';

import chairman_image from '../images/the-chairman-round.png';
import ceo_image from '../images/the-ceo-round.png';
import president_image from '../images/the-president-round.png';
import director_image from '../images/the-director-round.png';
import now_then from '../json_files/now-then.json';
import sharedInfo from '../json_files/shared-info.json';

const {height,width} = Dimensions.get('window');


const CouncilSeat = (props) => {
  
  return (
    // {/* council seat */}
    <View style={{
      width:'95vw',
      height:'90%',
      backgroundColor:'',
      borderRadius:'30px'
    }}>
      <LinearGradient colors={['black','#935be9']}
         style={{height:'100%',width:'100%',borderRadius:'30px'}}>
    
      {/* council title */}
      <View style={{
        width:'100%',
        height:'15%',
        alignItems:'center',
        justifyContent:'center',
      }}>
        <Text style={{color:'white',fontSize:'2.5em',fontFamily:'lores-15'}}>
          {props.seatTitle}
        </Text>
      </View>
      
      {/* council image */}
      <View style={{width:'100%',height:'50%',alignItems:'center', justifyContent:'center'}}>
      <View style={{height:'100%',width:'70%',justifyContent:'center',backgroundColor:''}}>
        <View style={{height:'80%',width:'100%',}}>
          <Image resizeMode='contain' source={props.councilImage} 
          style={{
            height:'100%',
            width:'100%'
          }}/>
        </View>
      </View>
      </View>
      
        
      
      
      <View style={{width:'100%',height:'35%', alignItems:'center', backgroundColor:''}}>
        
        <View style={{width:'90%',height:'100%', justifyContent:'center',backgroundColor:''}}>
          <Text style={{color:'white',fontSize:'1.3em',fontFamily:'consolas',textAlign:'center'}}>
            {props.seatText}
          </Text>
        </View>
      </View>
      
      </LinearGradient>
    </View>
    
  )
}


const NTPage = (props) => {
  
  const [ntdata, setNtdata] = useState(now_then);
  
  return(
    <View style={{ alignItems:'center',backgroundColor:''}}>
      
      <View style={{height:'8vh',width:'100%',alignItems:'flex-end',backgroundColor:''}}>
        <Pressable onPress={() => {props.menucloser();}} style={{height:'100%',width:'10%',margin:'3%'}}>
        <View style={{height:'100%',width:'100%',backgroundColor:''}}>
          <IconContext.Provider
  					value={{size:'100%',color:'white'}}>
  					<RiCloseCircleLine />
  				</IconContext.Provider>
        </View>
        </Pressable>
        
      </View>
      
      <View style={{width:'90%'}}>
        <View style={{width:'100%',marginTop:'5%',marginBottom:'5%',alignItems:'center'}}>
          <Text style={{color:'white',fontSize:'2em',fontFamily:'lores-15'}}>
            {(props.showtype=='then') && ntdata.then.title}
            {(props.showtype=='now') && ntdata.now.title}
          </Text>
        </View>
        
        <View>
          <Text style={{color:'white',fontFamily:'consolas'}}>
            {(props.showtype =='then') && ntdata.then.text}
            {(props.showtype =='now') && ntdata.now.text}
          </Text>
        </View>
      </View>
      
      
      
    </View>
  )
}



/*
███    ███  █████  ██ ███    ██ ██████   █████   ██████  ███████
████  ████ ██   ██ ██ ████   ██ ██   ██ ██   ██ ██       ██
██ ████ ██ ███████ ██ ██ ██  ██ ██████  ███████ ██   ███ █████
██  ██  ██ ██   ██ ██ ██  ██ ██ ██      ██   ██ ██    ██ ██
██      ██ ██   ██ ██ ██   ████ ██      ██   ██  ██████  ███████
*/
/*

█████ █████ █████ █████ █████ █████ █████ █████ █████ █████ █████ █████ █████ █████
*/




const HomePage = (navigation) => {
	
  var [page,setPage] = useState(1);
  var [showPage, setShowPage] = useState(false);
  var [pageType, setPageType] = useState('');
  
  
  
  const seatBuffer = 10;
  const rangeBuffer= 20;
  const b1Offset=width/5
  
  const handleScroll = (event) => {
    const scrollOffset = event.nativeEvent.contentOffset.x;
    const max = width*4
    // console.log(scrollOffset,width);
    if (scrollOffset < width) {
      setPage(1);
    }
    else if (scrollOffset > width-rangeBuffer && scrollOffset < width*2 ) {
      setPage(2);
    }
    else if (scrollOffset>(width*2)-rangeBuffer && scrollOffset < width*3) {
      setPage(3);
    }
    else if (scrollOffset>(width*2)-rangeBuffer && scrollOffset < width*4) {
      setPage(4);
    }
  }
  
  const closeMenu = () => {
    setShowPage(false);
  } 
  
  const handleMenu = (cont) => {
    setPageType(cont);
    setShowPage(true)
  }
  
  
  useEffect(() => {
    // console.log(Dimensions.get('window'));
  },[])
  
  const styles=StyleSheet.create({
    trackIdle:{
      width:'10px',
      height:'10px',
      borderRadius:'5px',
      backgroundColor:'white'
    },
    trackActive:{
      width:'10px',
      height:'10px',
      borderRadius:'5px',
      backgroundColor:'rgba(255,255,255,.5)'
    },
    trackCont:{
      flex:1,
      alignItems:'center1'
    }
    
  })
  
  
	
	return(
		<View style={{
      height:'100vh',
      backgroundColor:'black',
      
    }}>
    
      {/* <Navbar /> */}
      
			
      <Image 
        style={{
          width:'100%',
          height:'20%'
        }}
        source={require('../images/ccc-banner2.png')}
       />
       
       <View style={{
         alignItems:'center',
         marginTop:'5%'
       }}>
         <Text style={{
           color:'white',
           fontSize:'2em',
           fontFamily:'lores-15'
         }}>
           SURVIVORS: 10,000
         </Text>
       </View>
       
       <View style={{width:'100%',alignItems:'center',marginTop:'3%'}}>
         <View style={{width:'70%'}}>
           <Text style={{color:'white',fontSize:'.65em'}}>
             A collection of generative NFTs engaged 
             in an eternal battle royale on the blockchain
           </Text>
         </View>
       </View>
       
       
       
       
      
       <View style={{
         width:'100%',height:'20%',
         justifyContent:'center',
         alignItems:'center', marginTop:'0%'
       }}>
       
        <View style={{width:'100%',alignItems:'center'}}>
          <Text style={{color:'white',fontFamily:'consolas'}}>
            Become a Member
          </Text>
        </View>
        
        <View style={{width:'100%',alignItems:'center'}}>
          
            <Link href={'https://opensea.io/collection/cutthroat-country-club'} style={{width:'45%',
              alignItems:'center', 
              borderRadius:'50px', 
              borderWidth:'2px',
              borderColor:'#935be9'
            }}>
              <Text style={{color:'#935be9',fontSize:'2.1em',fontFamily:'consolas'}}>
                OPENSEA
              </Text>
            </Link>
          
        </View>
        
      </View>
         
         
      <View style={{width:'100%',height:'40%'}}>  
       <View style={{width:'100%',alignItems:'center',marginTop:'0%'}}>
         <Text style={{color:'white',fontFamily:'lores-15',fontSize:'2.2em'}}>
           THE AWAKENING
         </Text>
       </View> 
       
        <Timer endTime={sharedInfo.launchDate}/>
          
      </View>
        
      
      {/* bathroom */}
      <View style={{
        width:'100%',
        height:'40%'
      }}>
        <ImageBackground source={require('../images/bathroom-3.gif')} 
        style={{
          width:'100%',
          height:'100%'
        }}>
          <View style={{
            height:'21%',
            width:'12%',
            left:'36%',
            top:'22%'
          }}
            >
            <Pressable onPress={() => {handleMenu('then')}} style={{width:'100%',height:'100%',backgroundColor:''}}>
              
            </Pressable>
          </View>
            
          <View style={{
            height:'20%',
            width:'12%',
            // backgroundColor:'black',
            position:'absolute',
            left:'58%',
            top:'23%'
          }}>
            <Pressable onPress={() => {handleMenu('now')}} style={{width:'100%',height:'100%',backgroundColor:''}}>
              
            </Pressable>
          </View>
          
        </ImageBackground>
      </View>
      
      {showPage && <NTPage showtype={pageType} menucloser={closeMenu}/>}
        
     <View style={{height:'80%',width:'100%',marginTop:'10%'}}>
      <ScrollView contentContainerStyle={{
        marginTop:'10%',
        marginBottom:'10%',
        height:'100%',
        width:width - 20,
      }}
      horizontal= {true}
      decelerationRate={0}
      snapToInterval={width - 60}
      pagingEnabled={true}
      snapToAlignment={"center"}
      onScroll={handleScroll}
      scrollEventThrottle={16}
      showsHorizontalScrollIndicator={false}
      contentInset={{
        top: 0,
        left: 30,
        bottom: 0,
        right: 30,
      }}>
        <View style={{height:'100%',marginLeft:seatBuffer,marginRight:seatBuffer}}>
          <CouncilSeat councilImage={chairman_image} seatTitle={'The Chairman'} seatText={"Mr. Moneybag`s himself, the artist who created CCC. I'll steal your kidneys and feed them to your mother for fun."}  />
        </View>
          
        <View style={{height:'100%',marginLeft:seatBuffer,marginRight:seatBuffer}}>
          <CouncilSeat councilImage={ceo_image} seatTitle={'The CEO'} seatText={"Code monkey extraordinaire, let's get technical. I'd kill a baby just to make sure adrenochrome wasn't a real thing."}  />
        </View>
          
        <View style={{height:'100%',marginLeft:seatBuffer,marginRight:seatBuffer}}>
          <CouncilSeat councilImage={president_image} seatTitle={'The President'} seatText={'Able to use Twitter. What\'s all this hoopla about "human rights"?'}  />
        </View>
        
        <View style={{height:'100%',marginLeft:seatBuffer,marginRight:seatBuffer}}>
          <CouncilSeat councilImage={director_image} seatTitle={'The Director'} seatText={'In charge of just about everything else. We really should bring back child labor.'}  />
        </View>
        
        
      </ScrollView>
     </View>
     
     <View style={{
       height:'3%',
       alignItems:'center',
       marginBottom:'10%'
     }}>
       <View style={{
         height:'100%',
         width:'20%',
         flexDirection:'row'
       }}>
       
        <View style={styles.trackCont}>
          <View style={ (page == 1) ? styles.trackIdle : styles.trackActive}></View>
        </View>
          
        <View style={styles.trackCont}>
          <View style={ (page == 2) ? styles.trackIdle : styles.trackActive}></View>
        </View>
          
        <View style={styles.trackCont}>
          <View style={ (page == 3) ? styles.trackIdle : styles.trackActive}></View>
        </View>
        
        <View style={styles.trackCont}>
          <View style={ (page == 4) ? styles.trackIdle : styles.trackActive}></View>
        </View>
          
        
      </View>
       
     </View>
     
     
       
     <Footer />
           
         
       
      
      
      
		</View>
	)
}


export default HomePage;





















