import React, {useState,useEffect} from "react";
import '../css_files/fonts.css';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable
} from 'react-native';

import Footer from './Footer';


const GovernancePlaceHolder = () => {
	
	return(
		<View style={{
			widht:'100vw',
			height:'90vh',
			alignItems:'center',
			justifyContent:'center'
		}}>
			
			
			<View style={{
				width:'80%',
				height:'90%',
				justifyContent:'center'
			}}>
			
				<View style={{
					width:'100%',
				}}>
					
					<View style={{
						height:'15%',
						alignItems:'center'
					}}>
						<Text style={{
							color:'white',
							fontSize:'2em',
							fontFamily:'lores-15'
						}}>
							Not yet available
						</Text>
					</View>
						
						
					<View>
						<Text style={{color:'white',fontFamily:'consolas',fontSize:'1.5em',
						}}>
						
							Governance is currently not available yet, sorry :(
							{'\n \n'}
							
							Once the Cutthroat Country Club launches governance will be 
							handled through the totally killer platform
							<Text style={{color:'#6ce0cf'}}> Tally.</Text>
						
						</Text>
					</View>
					
				</View>
			</View>
			
			
			
			
			<Footer />
		</View>
	)
}



export default GovernancePlaceHolder;