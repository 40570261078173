import React, {useState,useEffect} from 'react';
import {AnimatePresence,useAnimation ,motion} from 'framer-motion';

import {injected} from '../connectors.js';
import {useWeb3React} from '@web3-react/core';
import {Web3} from 'web3';
import {ethers} from 'ethers';

import key from "../blockchain/private_key.json";
import nft_abi from "../blockchain/ccc-nft.json";
import contractInfo from "../json_files/contract-data.json";
import sharedInfo from '../json_files/shared-info.json';

import '../css_files/whitelist_mint.css';


import MMNext from '../images/metamaskNext.PNG' ;
import MMConnect from '../images/metamaskConnect.PNG' ;





var provider=null;
var contract=null;
var wallet = null;
var accounts = null;
var signer=null;

const loadWeb3 = async() => {
				if (window.ethereum) {
					window.ethereum.enable();
					provider = await new ethers.providers.Web3Provider(window.ethereum);
				}
			}
			
			
async function createWallet() {
	accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
	signer=await provider.getSigner(accounts[0]);
	// wallet =  await new ethers.Wallet(key.privateKey,provider);
}

async function loadContract() {
	
	const tempContract = await new ethers.Contract(contractInfo.contractAddress,nft_abi,provider);
	const withSigner = await tempContract.connect(signer);
	contract = withSigner;
}

async function setup() {
	await loadWeb3();
	await createWallet();
	await loadContract();
}



setup();








const WhiteListMinting = () => {
	
	const [whitelisted, setWhitelisted] = useState(false);
	const [allowance, setAllowance] = useState(0);
	const [mintRate, setMintRate] = useState('');
	const [mintError, setMintError] = useState('');
	
	const {active,account,library,connector,activate,deactivate} = useWeb3React();
	
	function resetError() {
		setMintError('');
	}
	
	function parseError(errorMessage) {
		const items = errorMessage.message;
		const startPosition = items.search('code')-2;
		const endPosition = items.search('originalError')-10;
		const trimmed = items.substring(startPosition,endPosition)+'}';
		const jsoned = JSON.parse(trimmed);  
		return jsoned.message;
	}
	
	
	async function checkCredentials() {
		var attempts=0;
		// keeps checking accounts until not null
		const checker = setInterval(async() => {
			if (accounts!=null) {
				
				
				const isOnWhitelist = await contract.isWhitelisted(accounts[0]);
				
				if (isOnWhitelist) {
					const ammount = await contract.whitelistAllowance(accounts[0]);
					const price = await ethers.utils.formatEther(await contract.whiteListMintRate());
				
					setAllowance(ammount);
					setMintRate(price);
				}
				setWhitelisted(isOnWhitelist);
				killChecker();
			} 
		},1000);
		
		const killChecker = () => {
			clearInterval(checker);
		}
		
	}
		
		
	
	// checks if the connected wallet is on the whitelist
	async function onloadCheck() {
		checkCredentials(); 
		resetError();
	}
	
	
	async function isWhitelisted() {
		const whitelisted = await contract.isWhitelisted(accounts[0]);
		return whitelisted;
	} 
	
	// returns whether the wallet has enough ether to mint an nft
	async function checkBalance() {
		const currentBalance = ethers.utils.formatEther(await provider.getBalance(accounts[0]));
		const mintPrice = ethers.utils.formatEther(await contract.whiteListMintRate());
		
		if (currentBalance > mintPrice) {
			
			resetError();
			return true;
		}
		else {
			return false;
		}
		
	}
	
	
	
	
	// mints an nft
	async function whitelistMint() {
		const hasMoney = await checkBalance();
		
		if (hasMoney) {
			const ethvalue = await contract.whiteListMintRate();
			const signer = provider.getSigner(accounts[0]);
			contract = await contract.connect(signer);
			try {
				const mintAttempt = await contract.safeMint({value:ethers.utils.parseEther('0.04')});	
			} catch(err) {
				setMintError(parseError(err));
			}
			checkCredentials();
				
			
		} 
		else if (!hasMoney) {
			setMintError('current balance is lower than the mintrate');
		}
		
	}
	
	async function doStuff() {
		const signer = await provider.getSigner(accounts[0]);
		contract = contract.connect(signer); 
		
		const estGas = await contract.estimateGas.AddToWhitelist(['0x353f4592E1f131CaC58c369fA9758ea37283DAf6'],['1']);
		const item = await contract.AddToWhitelist(['0x353f4592E1f131CaC58c369fA9758ea37283DAf6'],['1']);
	}
	
	
	useEffect(() => {
		onloadCheck();
	},[])
	
	
	const openseaDirect = () => {
		window.location.href='https://opensea.io/collection/cutthroat-country-club';
	}
	
	const NotWhitelisted = () => {
		
		const JoinStep = (props) => {
			
			return(
				<div className='joinstep-main'>
					
					<span className='jstep-number'>{props.stepNumber+'. '}</span>
					<span className='jstep-text'>
						{props.stepText}
					</span>
					
				</div>
			)
		}
		
		
		return(
			<div className='main-body'>
				
				<div className='section1'>
					
					<div className='item-1'>
						Hmm...
					</div>
					<div className='s1-title'>
						 it doesnt look like you are on the whitelist.
					</div>
					<br />
					<div className='item-2'>
						Skills such as yours make it pretty far
						<br />
						in this community...
						<br />
						<br />
						Consider this offer:
					</div>
					
					<div className='seper'></div>
					
					<div className='join-steps-main'>
						<div className='join-steps-inner'>
						
							<div className='join-1'>
								To join our WL, complete the following steps:
							</div>
							
							<JoinStep stepNumber='1' stepText='Follow us on Twitter' />
							<JoinStep stepNumber='2' stepText='Like & Retweet our pinned post' />
							<JoinStep stepNumber='3' stepText='Join our Discord server' />
							<JoinStep stepNumber='4' stepText='Invite 5 friends to our Discord using your invite link' />
						
						</div>
						
						<div className='join-steps-inner2'>
							Take a screenshot as proof that you have completed all the requirements & send it 
							to the discord’s “whitelist-proof” channel, & we will reserve your WL spot!
						</div>
						
						<div className='join-steps-inner3'>
							<span className='wl-info'>WL Price: </span>{sharedInfo.wlPrice}
							<br/>
							<span className='wl-info'>Mint Limit: </span>{sharedInfo.mintLimit}
							<br/>
							<span className='wl-info'>Total WL spots: </span>{sharedInfo.totalSpots}
							<br/>
							<span className='wl-info'>Presale: </span>{sharedInfo.presaleDate}
							<br/>
							<span className='wl-info'>Launch Price: </span>{sharedInfo.launchPrice}
							<br/>
							<span className='wl-info'>Launch Date: </span>{sharedInfo.launchDate}
							
							 
							<br/>	
							<br/>
							<br/>
							<br/>
							<br/>
						</div>
						
					</div>
				
				
					
				</div>
				
			</div>
		)
	}
	
	
	const Errorsection = () => {
		return(
			<div>
				
			
			<span style={{
				color:'red',
			}}>
				Error: 
			</span>
			<span style={{color:'white'}}>{mintError}</span>
			</div>
		)
	}
	
	
	const Whitelisted = () => {
		
		return(
			<div className='whitelist-outer'>
				
			
				<div className='whitelist-main'>
				
					<div className='wlleft-side'>
						<div className='welcome'>
							Welcome
						</div>
						<div className='intro-part2'>
							to the Whitelist, we've been expecting you.
							White Collar warriors from all over the world have fought long and hard
							to become a
							<span className='highlight'> Cutthroat</span>, but few have succeeded.
							Looks like you've made the cut-
							<br />
							<br />
							Congrats!
						</div>
					</div>
					<div className='wlmiddle-line-holder'>
						<div className='wlmiddle-line'>
							
						</div>
					</div>
					<div className='wlright-side'>
					
					<div className='wlright-holder'>
						<div className='mints-left'>
							<span className='highlight'>Mints left:</span> {allowance.toString()}
						</div>
						<div className='mint-price'>
							<span className='highlight'>Price</span>: {mintRate} ETH
						</div>
						
						<div className='mint-button-holder'>
							
							
							
							<button onClick={whitelistMint} className='mint-button'>
								MINT
							</button>
							
							<div className='error-box'>
								{(mintError != '' ) && <Errorsection />}
							</div>
							
						</div>
					
						
					</div>
					
				</div>		
			</div>
		</div>
		)
	}
	
	const NotConnected = () => {
		
		const StepImage = (props) => {
			return(
				<div className='step-image-holder'>
					<img className='step-image' src={props.image}/>
				</div>
			)
		}
		
		
		
		return(
			<motion.div className='not-connected-main'>
				<div className='not-connected-inner'>
					<div className='not-connected-title'>
						Not Connected
					</div>
					
					<div className='not-connected-message-1'>
						
						It doesn't seem like your wallet is connected.
						
					</div>
					<div className='not-connected-message-2'>
						Do us a favor and press the connect button
					</div>
					
				
				</div>
			</motion.div>
		)
	}
	
	const IsConnected = () =>{
		
		return(
			<div className='connected-main'>
				{whitelisted ?  <Whitelisted /> : <NotWhitelisted />}
			</div>
		)
			
	}
	
	
	
	return(
		
		<div className='whitelist-body'>
			{/* <AnimatePresence mode='wait'> */}
				{active ?  <IsConnected /> : <NotConnected />}
			{/* </AnimatePresence> */}
		</div>
		
	)
}



export default WhiteListMinting;











