import react, {useEffect,useState} from 'react';
import {AnimatePresence,motion} from 'framer-motion';



import '../css_files/governance.css';



const GovernancePlaceHolder = () => {
	
	return(
		<motion.div className='gov-main-body'
			initial={{
				opacity:0,
				transition:{
					duration:.5
				}
			}}
			animate={{
				opacity:1,
				transition:{
					duration:.5
				}
			}}
			exit={{
				opacity:0,
				transition:{
					duration:.5
				}
			}}>
			<div className='gov-notification'>
				
				<div className='notifcation-title'>
					Not yet available
				</div>
					
			
				<div className='notifcation-text'>
					Governance is currently not available yet, sorry :(
					<br />
					<br />
					Once the Cutthroat Country Club launches governance will be handled through the tottally
					platform <span style={{color:'#6ce0cf'}}>Tally</span>. 
				</div>
				
				
			
			</div>
		</motion.div>
	)
}


export default GovernancePlaceHolder;