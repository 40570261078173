import react, {useEffect,useState} from 'react';
import {AnimatePresence,motion} from 'framer-motion';
import CountDownTimer from '../Timer/react_timer/CountDown';
import {IconContext} from 'react-icons';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import sharedInfo from '../json_files/shared-info.json';

import '../css_files/HomePage.css';
import nt_text from '../json_files/now-then.json';

// needed images
import main_banner from '../images/ccc-banner2.png';
import alarm_clock from '../images/alarm-clock.png';

import chairman_clear from '../images/the-chairman-round.png';
import chairman_shade from '../images/the-chairman-shade-round.png';

import ceo_clear from '../images/the-ceo-round.png';
import ceo_shade from '../images/the-ceo-shade-round.png';

import president_clear from '../images/the-president-round.png';
import president_shade from '../images/the-president-shade-round.png';

import director_clear from '../images/the-director-round.png';
import director_shade from '../images/the-director-shade-round.png';

import bathroom from '../images/bathroom-3.gif';
// ^^^^^^^^^^^^







const Banner = () => {
	
	return (
		<div className='banner-container'>
				<img className='banner' src={main_banner} />
		</div>
	)
}




const HomePage = () => {
	
	const [thenOpen, setThenOpen] = useState(false);
	const [nowOpen, setNowOpen] = useState(false);
	
	
	
	useEffect(() => {
		
			
		const chairman = document.querySelector('#chairman');
		const ceo = document.querySelector('#ceo');
		const president = document.querySelector('#president');
		const director = document.querySelector('#director');
		
		const showchairman = event => {document.querySelector('.chairman-image').style.backgroundImage = 'url('+chairman_clear+')';}
		const showceo = event => {document.querySelector('.ceo-image').style.backgroundImage = 'url('+ceo_clear+')';}
		const showpresident = event => {document.querySelector('.president-image').style.backgroundImage = 'url('+president_clear+')';}
		const showdirector = event => {document.querySelector('.director-image').style.backgroundImage = 'url('+director_clear+')';}
		
		const hidechairman = event => {document.querySelector('.chairman-image').style.backgroundImage = 'url('+chairman_shade+')';}
		const hideceo = event => {document.querySelector('.ceo-image').style.backgroundImage = 'url('+ceo_shade+')';}
		const hidepresident = event => {document.querySelector('.president-image').style.backgroundImage = 'url('+president_shade+')';}
		const hidedirector = event => {document.querySelector('.director-image').style.backgroundImage = 'url('+director_shade+')';}
		
			
		chairman.addEventListener('mouseover',showchairman);
		ceo.addEventListener('mouseover',showceo);
		president.addEventListener('mouseover',showpresident);
		director.addEventListener('mouseover',showdirector);
		
		chairman.addEventListener('mouseout',hidechairman);
		ceo.addEventListener('mouseout',hideceo);
		president.addEventListener('mouseout',hidepresident);
		director.addEventListener('mouseout',hidedirector);
	
		return () => {
			chairman.removeEventListener('mouseover',showchairman);
			ceo.removeEventListener('mouseover',showceo);
			president.removeEventListener('mouseover',showpresident);
			director.removeEventListener('mouseover',showdirector);
			
			chairman.removeEventListener('mouseout',hidechairman);
			ceo.removeEventListener('mouseout',hideceo);
			president.removeEventListener('mouseout',hidepresident);
			director.removeEventListener('mouseout',hidedirector);
		}
		
	},[])
	
	
	const ThenSection = () => {
		
		return(
			<motion.div className='then-section'
				initial={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				animate={{
					opacity:1,
					transition:{
						duration:.5
					}
				}}
				exit={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				>
				
				<IconContext.Provider
					value={{className:'then-close-icon'}}>
					<div onClick={() => {setThenOpen(!thenOpen)}} className='then-close'>
							<AiOutlineCloseCircle />
					</div>
				</IconContext.Provider>
						
				
				<div className='then-inner'>
					<div className='then-title is-family-primary'>
						THEN
					</div>
					<div className='then-text is-family-primary'>
						
						The year was 2048, and things were not looking too hot- McDonald's had been 
						the president of the U.S. for 3 terms running, 
						the entire country of Norway had been on fire for like 15 years running and 
						the moon colony revolted again. 
						
						<br/>
						<br/>
						
						You got filthy rich on NFTs and crypto because you knew when to buy and when 
						to sell and that same intuition told you to get the 
						hell out of dodge before it's too late, so you and 9,999 other moneybags fatcats 
						decided to get cryogenically frozen at the swanky 
						country club you all attended until whatever nonsense going on sorted itself out.
			
					</div>
				</div>
			</motion.div>
		)
	}
	
	const NowSection = () => {
		
		return(
			<motion.div className='now-section'
				initial={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}
				animate={{
					opacity:1,
					transition:{
						duration:.5
					}
				}}
				exit={{
					opacity:0,
					transition:{
						duration:.5
					}
				}}>
				
				<IconContext.Provider
					value={{className:'now-close-icon'}}>
					<div onClick={() => {setNowOpen(!nowOpen)}} className='now-close'>
							<AiOutlineCloseCircle />
					</div>
				</IconContext.Provider>
						
				
				<div className='now-inner'>
					<div className='now-title is-family-primary'>
						NOW
					</div>
					<div className='now-text is-family-primary'>
						
						The year is 2569. You have finally awoken from your centuries-long cryogenic coma that allowed you to live through armageddon 
						safe and cozy while the rest of the world tore itself apart, and it sure did some damage.
						<br />
						<br />
						The world is in ruins, basically inhabitable. Food is scarce and water is even scarcer, and worst of all, no one knows exactly 
						what happened while you were asleep. 
						<br />
						<br />
						Your killer instincts kept you alive before, and again they are all you have to rely on as you scavenge the wreckage for resources 
						and fight off others doing the same. It's a constant battle for survival against the rest of the 1%, where only the most vicious and 
						devious will prosper, and that's exactly how you like it. It's Cutthroat Country Club.
				
					</div>
				</div>
			</motion.div>
		)
	}
	
	
	
	return (
		
		<motion.div className='upper'
			initial={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}
			animate={{
				opacity:1,
				transition: {
					duration:.5
				}
			}}
			exit={{
				opacity:0,
				transition: {
					duration:.5
				}
			}}
			>
		
		<Banner />
		
			<div className='body-inner'>
				<div className='survivor-count is-family-secondary'>
					SURVIVORS: 10,000
				</div>
			
			
			<div className='description-one is-family-primary'>
				A collection of generative NFTs engaged in an eternal battle 
				royale on the blockchain.
			</div>
			
			<div className='opensea-portion'>
				<div className='ops-text is-family-primary'>Become a Member</div>
				<div className='ops-button-holder'>
					<a href='https://opensea.io/collection/cutthroat-country-club'>
						<button className='ops-button is-family-primary'>
								<div className='ops-inner'>OPENSEA</div>
						</button>
					</a>
				</div>
			</div>
			
			<div className='the-awakening'>
				
				<div className='awake-text is-family-secondary'>
					THE AWAKENING
				</div>
				
				<div className='clock-main'>
						
					<div className='time-holder'>
						<CountDownTimer endTime={sharedInfo.launchDate} />
					</div>
				</div>
				
				
			</div>
			
			
			
			
			<div className='copy-section'>
				<div className='bathroom-holder'>
				
					
					<div className='box-holder'>
						<div onClick={() => {setThenOpen(!thenOpen)}} className='then-box'></div>
						<div onClick={() => {setNowOpen(!nowOpen)}} className='now-box'></div>
					</div>
						
						
					<AnimatePresence>
						{thenOpen && <ThenSection />}
						{nowOpen && <NowSection/>}
					</AnimatePresence>
						
				</div>
			</div>
			
			
			<div className='shadow-council'>
				
				<div className='council-title is-family-secondary'>
					The Shadow Council
				</div>
				<div className='council-sub is-family-primary'>
					The team behind Cutthroat Country Club
				</div>
				
				<div className='council-chairs'>
					
					<div id='chairman' className='council-seat'>
						<div className='seat-title'>The Chairman</div>
						<div className='chairman-image'></div>
						
						<div className='seat-text'>
							Mr. Moneybag`s himself, the artist who created CCC. 
							I'll steal your kidneys and feed them to your mother for fun.
						</div>
						
						
					</div>
					
					<div id='ceo' className='council-seat'>
						<div className='seat-title'>The CEO</div>
						<div className='ceo-image'></div>
						
						<div className='seat-text'>
							Code monkey extraordinaire, let's get technical. 
							I'd kill a baby just to make sure adrenochrome wasn't a real thing.
						</div>
					</div>
					
					<div id='president' className='council-seat'>
						<div className='seat-title'>The President</div>
						<div className='president-image'></div>
						
						<div className='seat-text'>
							Able to use Twitter. What's all this hoopla about "human rights"?
						</div>
					</div>
					
					<div id='director' className='council-seat'>
						<div className='seat-title'>The Director</div>
						<div className='director-image'></div>
						
						<div className='seat-text'>
							In charge of just about everything else. We really should bring back child labor.
						</div>
					</div>
					
				</div>
				
				
			</div>
			
			
			
			</div>
		</motion.div>
		
		
	)
}


export default HomePage;















