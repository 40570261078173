
const server_url="https://zcyadst9pub0.usemoralis.com:2053/server";
const app_id="XcSeoV4xsMQYJXskjFT5gG1GWGHiatAebtoevVD2";
const navPageLimit=3;








//----------------------------------------------
//----------------------------------------------
// create local storage variables
export function setStorage() {
	
	const initVars={
		'firstTime':true,
		'connected':false,
		'account':null
	}
	
	for (const [key,value] of Object.entries(initVars)) {
		
		if (!(key in sessionStorage)){
			sessionStorage.setItem(key,value);
		}
		
	}
		
	
	
}


export function clearCookies(){
             var cookies = document.cookie.split(";");
             for (var i = 0; i < cookies.length; i++) {
                 var cookie = cookies[i];
                 var eqPos = cookie.indexOf("=");
                 var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                 document.cookie = name + "=;expires=" + Date(0);
 
 }
}
//----------------------------------------------
//----------------------------------------------




//----------------------------------------------
//----------------------------------------------
export function getVars(){
	
	const items=document.cookie.split(';')
								  .map(cookie => cookie.split('='))
								  .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});
	
	return items;
}
//****************************************
export function setVar(varName,newValue){
	document.cookie=(varName+"="+newValue);	
}

	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	