import dayjs from 'dayjs';





function getDifDays(start,end) {
	const days = end.diff(start, 'days') ;
	return days.toString();
}

function getDifHours(start,end) {
	const hours = end.diff(start, 'hours') % 24;
	return zero_pad(hours,2);
}

function getDifMinutes(start,end) {
	const minutes = end.diff(start, 'minutes') % 60;
	return zero_pad(minutes,2);
}

function getDifSeconds(start,end) {
	const seconds = end.diff(start, 'seconds') % 60;
	return zero_pad(seconds,2);
}


function zero_pad(number,size) {
	const nstring = number.toString();
	const num_array = [];
	if (nstring.length >= size) {
		
		for (var i=0; i<nstring.length; i++) {
			num_array[i]=nstring[i];
		}
		
		return num_array;
	}
	
	else {
		for (var i=0; i<size-nstring.length; i++) {
			num_array[i]='0';
		}
		num_array[num_array.length]=nstring;
		
		return num_array ;
	}
	
}


export function convertTime(timestamp) {
	
	const stampObject = dayjs(timestamp);
	const nowObject = dayjs();
	
	
	
	const seconds = getDifSeconds(nowObject,stampObject);
  const minutes = getDifMinutes(nowObject,stampObject);
	const hours = getDifHours(nowObject,stampObject);
	const days = getDifDays(nowObject,stampObject);
	
	// console.log(seconds);
	
	return {
		d:days,
		h1:hours[0],
		h2:hours[1],
		m1:minutes[0],
		m2:minutes[1],
		s1:seconds[0],
		s2:seconds[1]
		
		
		
		
	}
	
	
}




















